.main {
  padding: 40px 0px 60px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #121315;
}
.cont {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: contain;
}
.contentCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.contentCont h1,
.contentCont h2,
.contentCont button {
  align-self: flex-start;
  text-align: start;
}
.contentCont h2 {
  color: #ec1d23;
}
.swiperCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  width: 100%;
  max-width: 550px;
  border-radius: 12px;
  padding: 40px 55px 40px 55px;
  border-radius: 12px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  gap: 30px;
}
.box img,
.box p,
.box div {
  align-self: flex-start;
  text-align: start;
}
.review {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}
.review h2 {
  align-self: center;
  color: black;
}
.review img {
  width: 100px;
  align-self: center;
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
  .box {
    gap: 20px;
    padding: 20px 30px 20px 30px;
  }
}
