.main {
  padding: 80px 0px 300px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: #050505;
}
.main p {
  color: white;
}
.main h3,
.main h3 span{
  color: #c21818;
}
.box {
  width: 100%;
  height: 400px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentCol1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 120px;
}
.contentCol2 {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.contentCol3 {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
}
.contentCol1 .contentBox {
  height: 320px;
  background-size: 80px 290px;
}
.contentCol2 .contentBox {
  margin-bottom: -400px;
  height: 350px;
  background-size: 170px 300px;
}
.contentCol2 .contentBox h3 {
  padding-left: 75px;
}
.contentCol2 .contentBox p {
  padding-left: 75px;
}
.contentCol3 .contentBox {
  margin-top: -60px;
  height: 330px;
}
.contentBox {
  width: 100%;
  background-size: 100px 280px;
  background-repeat: no-repeat;
  object-fit: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}
.contentBox h3 {
  padding-left: 45px;
  text-align: start;
  align-self: start;
}
.contentBox p {
  padding-left: 45px;
  text-align: start;
  align-self: start;
}
.mobBox {
  display: none;
}
.contentMobBox {
  width: 100%;
  background-size: 18% 60%;
  background-repeat: no-repeat;
  object-fit: contain;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  height: 200px;
}
.contentMobBox h3 {
  padding-left: 55px;
  text-align: start;
  align-self: start;
}
.contentMobBox p {
  padding-left: 55px;
  text-align: start;
  align-self: start;
}
@media (max-width: 1200px) {
  .main {
    padding: 40px 0px 200px 0px;
  }
  .box {
    height: 600px;
  }
  .contentCol1 {
    gap: 90px;
  }
  .contentCol1 .contentBox {
    height: 380px;
  }
  .contentCol2 .contentBox {
    margin-bottom: -360px;
  }
  .contentCol3 .contentBox {
    margin-top: -10px;
  }
}
@media (max-width: 992px) {
  .main {
    padding: 40px 0px 40px 0px;
  }
  .box {
    display: none;
  }
  .mobBox {
    width: 100%;
    height: 100%;
    display: block;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .contentMobBox h3 {
    padding-left: 45px;
  }
  .contentMobBox p {
    padding-left: 45px;
  }
}
@media (max-width: 480px) {
  .contentMobBox h3 {
    padding-left: 25px;
  }
  .contentMobBox p {
    padding-left: 25px;
  }
}
