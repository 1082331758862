.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  background: linear-gradient(to bottom, #080707, #080707, #050505);
}
.boxCont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.swiperSlide{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 280px;
}
.box {
  border-radius: 12px;
  width: 220px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.box img {
  cursor: pointer;
  height: 200px;
  max-width: 200px;
}
.box h5 {
  color: #d82e34;
  text-decoration: underline 1px #d82e34;
}
@media (max-width: 1400px) {
  .box {
    width: 160px;
    height: 160px;
  }
  .box img {
    height: 120px;
    max-width: 120px;
  }
}
@media (max-width: 992px) {
  .boxCont {
    gap: 45px;
    justify-content: center;
  }
}
@media (max-width: 520px) {
  .boxCont {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
