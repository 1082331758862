.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  background: linear-gradient(to top, #353638, #121315);
}
.contentCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 20px;
}
.contentCont img,
.contentCont h1,
.contentCont h2 {
  align-self: center;
  text-align: center;
}
.contentCont img {
  max-width: 170px;
}
.contentCont h2 {
  color: #ec1d23;
}
.list {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.listItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #525252;
}
.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 30px;
  cursor: pointer;
}
.head h2 {
  text-transform: none;
  padding-right: 10px;
  color: white;
  font-weight: 400;
}
.head img {
  width: 25px;
  height: 25px;
}
.listItem p {
  align-self: flex-start;
  text-align: start;
  color: #dfdfdf;
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
}
