.main {
  padding: 100px 0px 100px 0px;
  width: 100%;
  height: 100%;
  min-height: 400px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  background-blend-mode: overlay;
  background-color: rgba(59, 59, 59, 0);
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.contentCol h1,
.contentCol h2,
.contentCol p {
  align-self: center;
  text-align: center;
  color: white;
}
.contentCol h2 {
  font-weight: 600;
  text-transform: uppercase;
}
.contentCol p {
  padding: 0px 16% 0px 16%;
}
.contentCol img {
  max-width: 40px;
  max-height: 40px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .main {
    min-height: 370px;
  }
  .contentCol h2 {
    padding-bottom: 0px;
  }
  .contentCol img {
    width: 30px;
    height: 30px;
  }
}
