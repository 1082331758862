.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  background-color: rgb(17, 18, 19);
}
.tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0f0f0f;
  border-radius: 25px;
  overflow: hidden;
}
.tab {
  width: 100%;
  padding: 25px 35px 25px 35px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border-radius: 1px solid white;
}
.tab p {
  color: #b1b1b1;
}
.tabActive {
  background-color: #ec1d23;
}
.tabActive p {
  color: white;
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 45px;
}
.contentCol a,
.contentCol h2,
.contentCol h1,
.contentCol p {
  align-self: flex-start;
  text-align: start;
}
.contentCol h2 {
  color: #ec1d23;
}
.contentCol p {
  color: rgb(215, 215, 215);
  white-space: pre-line;
}
