.main {
  width: 100%;
  height: 100vh;
}
.hero {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.inner {
  padding-top: 120px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    #080707
  );
}
.contentCont {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.contentCont button {
  align-self: flex-start;
}
.contentCont h1,
.contentCont h2,
.contentCont p,
.contentCont a {
  align-self: flex-start;
  text-align: start;
}
.contentCont p {
  padding-top: 15px;
  padding-bottom: 15px;
}
.iconsCont {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}
.iconsCont img {
  width: 100px;
  height: 100px;
}
.imgCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgCol img {
  width: 85%;
}
@media (max-width: 1200px) {
  .contentCont {
    padding-right: 0px;
  }
  .iconsCont img {
    width: 65px;
    height: 65px;
  }
}
@media (max-width: 992px) {
  .main {
    height: auto;
  }
  .hero {
    height: auto;
  }
  .inner {
    min-height: 80vh;
    padding: 140px 0px 50px 0px;
  }
}
