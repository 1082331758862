.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.imgCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 80px;
}
.imgCont img {
  width: 160px;
  height: 160px;
}
@media (max-width: 1400px) {
  .imgCont {
    gap: 50px;
  }
  .imgCont img {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
  .imgCont {
    gap: 30px;
    flex-direction: column;
  }
  
}
