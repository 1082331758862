.main {
  padding: 0px 0px 80px 0px;
  width: 100%;
  background: linear-gradient(to bottom, #080707, #080707, #121315);
}
.swiperCont {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding: 40px 30px 0px 30px;
  background-color: #191919;
  /* background: linear-gradient(to right, #888888, #88888894); */
  border-radius: 15px;
  max-width: 450px;
  max-height: 450px;
}
.contentCol {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 20px;
}
.box img {
  height: 460px;
  margin-top: -100px;
  max-width: 190px;
  min-width: 190px;
}
@media (max-width: 1200px) {
  .main {
    padding: 50px 0px 60px 0px;
  }
  .swiperSlide {
    height: 400px;
  }
}
@media (max-width: 600px) {
  .box {
    padding: 40px 15px 0px 15px;
    max-width: 350px;
    min-height: 240px;
  }
  .box img {
    max-width: 120px;
    min-width: 120px;
    height: 260px;
    margin-top: 0px;
  }
}
@media (max-width: 480px) {
  .box img {
    max-width: 100px;
    min-width: 100px;
    height: 200px;
  }
}
