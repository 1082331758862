.main {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  padding-top: 200px;
  color: rgba(0, 0, 0, 0.868);
}
.contentCol,
.formCol form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contentCol h1,
.contentCol h2,
.contentCol p {
  text-align: start;
  align-self: flex-start;
}
.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}
.info img {
  width: 30px;
  height: 30px;
  padding: 8px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid white;
}
.info p,
.formCol p,
.contentCol p {
  color: #ffffff;
}
.formCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #b5b5b52a;
  backdrop-filter: blur(15px);
  border-radius: 12px;
  border: 2px solid rgb(180, 180, 180);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.071);
  padding: 65px 50px 65px 50px;
}
.formCol h1,
.formCol p {
  align-self: flex-start;
  text-align: start;
}
.formCol form {
  gap: 30px;
}
.formCol form input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  color: #efefef;
  padding: 0px 0px 10px 0px;
}
.formCol form input::placeholder {
  color: #efefef;
}
.formCol form input:focus {
  outline: none;
}
.formCol form button {
  align-self: flex-start;
}
@media (max-width: 992px) {
  .main {
    padding-top: 130px;
  }
}
