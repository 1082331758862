* {
  user-select: none;
}
*::-webkit-scrollbar {
  width: 14px;
}
*::-webkit-scrollbar-thumb {
  background-color: #ec1d23;
  border-radius: 4px;
}
*::-webkit-scrollbar-track {
  background-color: #232323;
}
.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  user-select: none;
}
.heading_capital2 {
  font-family: "Poppins", sans-serif;
  font-size: 80px;
  text-transform: capitalize;
  color: #000000;
  font-weight: 900;
  margin: 0;
}
.heading_capital2 span {
  background: linear-gradient(to right, #db2d33, #a11a1f);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.heading_capital {
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  color: #000000;
  font-weight: 900;
  margin: 0;
}
.heading_capital span {
  background: linear-gradient(to right, #db2d33, #a11a1f);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.h1_main {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  color: #ffffff;
  font-weight: 800;
  margin: 0;
}
.h1_main span {
  background: linear-gradient(to right, #db2d33, #a11a1f);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}
.h2_main {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin: 0;
}
.h2_main span {
  text-decoration: underline 3px solid #8d8d8d;
  text-underline-offset: 10px;
}
.h3_main {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  color: #fff;
  font-weight: 900;
  margin: 0;
}
.h3_main span {
  color: rgba(0, 0, 0, 1);
}
.h4_main {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  margin: 0;
}
.h4_main span {
  color: rgba(0, 0, 0, 1);
}
.h5_main {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  margin: 0;
}
.h5_main span {
  color: rgba(0, 0, 0, 1);
}
.para_main {
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-align: start;
  color: #000000;
  margin: 0;
}
.button_main {
  border-radius: 5px;
  padding: 10px 30px 10px 30px;
  border: none;
  background: linear-gradient(to right, #db2d33, #a11a1f);
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  transition: 0.2s ease;
}
.button_main:hover {
  background: linear-gradient(to right, #ea353b, #8f181c);
}
@media (max-width: 767px) {
  *::-webkit-scrollbar {
    width: 12px;
  }
  .main {
    padding: 60px 0px 60px 0px;
  }
  .heading_capital2 {
    font-size: 35px;
  }
  .heading_capital {
    font-size: 25px;
  }
  .heading_capital span {
    font-size: 30px;
  }
  .h1_main {
    font-size: 23px;
  }
  .h2_main {
    font-size: 18px;
  }
  .h3_main {
    font-size: 17px;
  }
  .h4_main {
    font-size: 13px;
  }
  .h5_main {
    font-size: 12px;
  }
  .para_main {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .heading_capital2 {
    font-size: 28px;
  }
  .heading_capital {
    font-size: 23px;
  }
  .heading_capital span {
    font-size: 26px;
  }
  .heading_main {
    font-size: 22px;
  }
}
