.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  background: #121315;
}
.swiperCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.box {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 10px;
  padding: 50px 40px 0px 40px;
  background-color: #262626;
  border-radius: 15px;
  gap: 25px;
}
.head {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}
.head img {
  width: 55px;
  height: 55px;
}
.head h2 {
  align-self: center;
  color: white;
  font-weight: 600;
}
.box p {
  color: white;
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
  .head img {
    width: 35px;
    height: 35px;
  }
  .box {
    padding: 30px;
  }
}
