.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.imgCont {
  width: 100%;
  height: 100%;
}
.imgCont img {
  width: 100%;
  max-width: 200px;
  rotate: -5deg;
  border-radius: 5px;
}
.contentCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}
.contentCont h1,
.contentCont p {
  align-self: flex-start;
  text-align: start;
  color: white;
}
.form {
  padding-left: 10%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.form input,
.form button {
  width: 100%;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
}
.form input {
  background-color: transparent;
  border: none;
  margin: 0;
  border: 2px solid #b2b2b2;
  color: #d6d6d6;
}
.form input:focus {
  outline: none;
}
.form input::placeholder {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #b2b2b2;
}
.form button {
  color: white;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  gap: 15px;
  background: linear-gradient(to right, #db2d33, #a11a1f);
}
.form button:hover {
  background: linear-gradient(to right, #c72f34, #8f181c);
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
  .form {
    padding-left: 0%;
  }
}
@media (max-width: 574px) {
  .imgCont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
