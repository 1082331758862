.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  background: #121315;
}
.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.head h2 {
  color: #ec1d23;
}
.box {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  background-color: #191919;
  border-radius: 12px;
}
.box img {
  width: 100%;
  border-radius: 12px;
}
.box h4 {
  font-weight: 400;
}
.bottomRow {
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.readMore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}
.readMore img {
  width: 16px;
  height: 16px;
}
.readMore h5 {
  text-decoration: underline;
}
.bottomRow p {
  color: #d1d1d1;
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
}
