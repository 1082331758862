.contact_section {
  padding: 120px 0px 120px 0px;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-blend-mode: overlay;
  font-family: "Mulish", sans-serif;
  position: relative;
  height: auto;
}

.contact_section h4,
.contact_section h2,
.contact_section p {
  color: #ffffff;
  align-self: flex-start;
  text-align: start;
}
.contact-details {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-right: 80px;
}

.contact_section button {
  background: #fff;
  color: #ab0909;
  font-family: "Poppins", sans-serif;
  padding: 9px 30px 9px 30px;
  font-size: 15px;
  font-weight: 900;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: 0.3s ease;
}
.contact_section button:hover {
  background-color: #ab0909;
  color: white;
  border: 1px solid white;
}
.contact_section .contact-details h4 {
  font-size: 16px;
}

/* Styling for input fields */
.contact_section input[type="text"],
.contact_section input[type="email"],
.contact_section textarea {
  border: none;
  border-bottom: 1px solid #d4d4d4;
  padding: 5px 0px 5px 0px;
  font-size: 16px;
  color: rgb(245, 245, 245);
  background: transparent;
  font-family: "Poppins", sans-serif;
  width: 100%;
}

/* Styling for input placeholders */
.contact_section input[type="text"]::placeholder,
.contact_section input[type="email"]::placeholder,
.contact_section textarea::placeholder {
  color: rgb(245, 245, 245);
  font-family: "Poppins", sans-serif;
}

/* Remove default styling for focus */
.contact_section input:focus,
.contact_section textarea:focus,
.contact_section select:focus {
  outline: none;
}

/* Optional additional styling for spacing */
.contact_section input,
.contact_section textarea,
.contact_section select {
  margin-bottom: 10px;
}
@media (max-width: 992px) {
  .contact_section {
    padding: 80px 0px 80px 0px;
  }
  .contact-details {
    padding-right: 0px;
  }
  .contact_section h4 {
    text-align: center;
    align-self: center;
  }
  .contact_section h2 {
    text-align: center;
    align-self: center;
  }
  .contact_section p {
    text-align: center;
    align-self: center;
  }
  .button_col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
