.main {
  padding: 140px 0px 140px 0px;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: contain;
}
.box {
  width: 100%;
  padding: 0px 20% 0px 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.box h1,
.box p,
.box button {
  align-self: center;
  text-align: center;
  color: white;
}
@media (max-width: 1200px) {
  .box {
    padding: 0px 15% 0px 15%;
  }
}
@media (max-width: 992px) {
  .main {
    padding: 80px 0px 80px 0px;
  }
  .box {
    padding: 0px 10% 0px 10%;
  }
}
