.main {
  padding: 50px 0px 50px 0px;
  width: 100%;
  background-color: #121315;
}
.imgCol,
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}
.imgCol img {
  width: 100%;
}
.contentCol button,
.contentCol h2,
.contentCol h1,
.contentCol p,
.contentCol a {
  align-self: flex-start;
  text-align: start;
}
.contentCol h2 {
  color: #ec1d23;
}
.contentCol p {
  color: rgb(215, 215, 215);
  white-space: pre-line;
}
@media (max-width: 992px) {
  .main {
    padding: 30px 0px 30px 0px;
  }
  .imgCol img {
    align-self: center;
  }
  .contentCol {
    gap: 25px;
    justify-content: start;
    align-items: start;
  }
}
