.main h2,
.main p {
  text-align: start;
  align-self: flex-start;
}
.main p {
  color: white;
}
.main h2 {
  color: #ec1d23;
  font-weight: 500;
}
.boxCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 30px;
}
.contentCol h1 {
  color: #ec1d23;
}
.contentBox {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  cursor: pointer;
}
.contentBox h4 {
  color: white;
  text-align: start;
  align-self: flex-start;
}
.contentBox p {
  color: rgb(186, 186, 186);
  text-align: start;
  align-self: flex-start;
}
.contentBox img {
  width: 200px;
  height: 120px;
  border-radius: 10px;
}
.footer {
  width: 100%;
  padding: 25px 0px 25px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(145, 145, 145);
}
.footer p {
  align-self: flex-end;
}
.footer p span {
  color: rgb(145, 145, 145);
}
.socials {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.socials a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e4e4e4;
  fill: #ec1d23;
  transition: 0.2s ease;
}
.socials svg {
  width: 17px;
  height: 17px;
}
.socials a:hover {
  background-color: red;
}
.socials a:hover svg {
  fill: #fff;
}
@media (max-width: 992px) {
  .contentBox img {
    width: 130px;
    height: 80px;
  }
}