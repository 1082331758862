.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  background: #050505;
}
.box {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 70px 70px 70px;
  gap: 20px;
}
.box h2,
.box p {
  text-align: center;
  align-self: center;
  color: white;
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
  .box {
    max-width: 420px;
    padding: 40px;
    border-radius: 50px;
    background-color: #191919;
  }
}
