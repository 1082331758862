.visa_swiper {
  cursor: pointer;
  user-select: none;
}
.visa_swiper .swiper-slide {
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.visa_swiper .swiper-pagination {
  margin-bottom: -10px;
}
.visa_swiper .swiper-pagination-bullet {
  padding: 7px;
  margin: 7px;
  background-color: transparent;
  border: 2px solid white;
}
.visa_swiper .swiper-pagination-bullet-active {
  background-color: #ec1d23;
  border-color: #ec1d23;
}
@media (max-width: 600px) {
  .visa_swiper .swiper-slide {
    height: 420px;
  }
  .visa_swiper .swiper-pagination-bullet {
    padding: 6px;
  }
  .visa_swiper .swiper-button-next {
    padding: 1px;
  }
}
