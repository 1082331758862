.main {
  padding: 100px 0px 100px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: #0b0a0a;
}
.main h1,
.main p {
  padding: 0 10% 20px 10%;
  text-align: center;
  color: white;
}
.rowCont {
  padding-top: 60px;
}

.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  transition: 0.5s ease;
}
.padding {
  padding-top: 120px;
}
.imgBackground {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.6s ease;
  min-height: 300px;
}
.imgBackground:hover {
  transform: scale(1.02);
}
.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.head div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}
.head div svg {
  width: 30px;
  height: 30px;
  fill: #cb1212;
  border-radius: 50%;
  border: 3px solid #cb1212;
  padding: 5px;
  cursor: pointer;
  transition: 0.3s ease;
}
.head div svg:hover {
  background-color: #cb1212;
  fill: #fff;
}
.head h2 {
  align-self: center;
  text-align: start;
  text-transform: none;
  color: #cb1212;
}
.head h4 {
  text-transform: none;
}
.tags {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.tags h5 {
  text-transform: none;
  width: 100%;
}
.tags h5 span {
  color: #cb1212;
  padding-right: 10%;
}
.readMore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  padding-top: 60px;
}
.readMore h2 {
  text-transform: none;
  align-self: center;
}
.readMore svg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #cb1212;
  fill: white;
  padding: 8px;
  cursor: pointer;
  transform: rotate(90deg);
  box-shadow: 0px 0px 25px rgba(31, 31, 31, 0.32);
  transition: 0.3s ease;
}
.readMore svg:hover {
  background-color: white;
  fill: #cb1212;
}
@media (max-width: 992px) {
  .main h1 {
    padding: 0 2% 25px 2%;
  }
  .rowCont {
    padding-top: 45px;
    gap: 60px;
  }
  .buttonCont {
    gap: 15px;
  }
  .padding {
    padding-top: 0px;
  }
  .imgBackground {
    min-height: auto;
  }
  .head {
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 10px;
  }
  .head h2 {
    align-self: flex-start;
  }
  .head div {
    align-self: flex-start;
  }
  .box {
    gap: 10px;
  }
}
@media (max-width: 778px) {
  
  .head div {
    gap: 10px;
  }
  .head div svg {
    width: 25px;
    height: 25px;
    border: 2px solid #cb1212;
    padding: 5px;
  }
}