.faq_slide {
  position: relative;
  width: 100%;
  overflow-y: hidden;
}
.faq_slide-in {
  animation: faq_slide-in 0.5s forwards;
  -webkit-animation: faq_slide-in 0.5s forwards;
}
.faq_slide-out {
  animation: faq_slide-out 0.5s forwards;
  -webkit-animation: faq_slide-out 0.5s forwards;
}
@keyframes faq_slide-in {
  0% { max-height: 0px; padding-bottom: 0px;}
  100% { max-height: 600px; padding-bottom: 15px;}
}
@-webkit-keyframes faq_slide-in {
  0% { max-height: 0px; padding-bottom: 0px;}
  100% { max-height: 600px; padding-bottom: 15px;}
}
@keyframes faq_slide-out {
  0% { max-height: 600px; padding-bottom: 15px;}
  100% { max-height: 0px; padding-bottom: 0px;}
}
@-webkit-keyframes faq_slide-out {
  0% { max-height: 600px; padding-bottom: 15px;}
  100% { max-height: 0px; padding-bottom: 0px;}
}