.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 0px 120px 0px;
  /* margin-top: -150px; */
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content h1 {
  text-align: start;
  align-self: flex-start;
  text-decoration: none;
  background: linear-gradient(to right, #db2d33, #a11a1f);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: none;
  font-weight: 900;
}
.content p {
  color: white;
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 30px;
}
.contentCol h1 {
  color: #ec1d23;
}
.contentBox {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  cursor: pointer;
}
.contentBox h4 {
  color: white;
  text-align: start;
  align-self: flex-start;
}
.contentBox p {
  color: rgb(186, 186, 186);
  text-align: start;
  align-self: flex-start;
}
.contentBox img {
  width: 200px;
  height: 120px;
  border-radius: 10px;
}
.imgCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.imgCont img {
  width: 100%;
  height: 100%;
}
@media (max-width: 1200px) {
  .main {
    margin-top: 0px;
    padding: 60px 0px 60px 0px;
  }
}
@media (max-width: 992px) {
  .contentBox img {
    width: 130px;
    height: 80px;
  }
}
