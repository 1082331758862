.main {
  width: 100%;
  height: auto;
}
.footer {
  padding: 80px 0px 45px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #191919;
}
.newsletter {
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(130, 130, 130);
}
.imgCont {
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgCont img {
  width: 60px;
  height: 60px;
}
.text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.text h3,
.text p {
  align-self: flex-start;
  text-align: start;
}
.text p {
  color: rgb(220, 220, 220);
  padding-right: 5%;
}
.pillCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.input {
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: white;
  border-radius: 50px;
  padding: 0px 0px 0px 30px;
}
.input input {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: 60%;
}
.input input:focus {
  outline: none;
}
.input input::placeholder {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #cacaca;
}
.input button {
  padding: 0px 30px 0px 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #e01d25;
  color: white;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  gap: 15px;
  border-radius: 50px;
}
.input button img {
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: 0.2s ease;
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  gap: 30px;
  padding-right: 25%;
}
.contentCol img {
  width: 100%;
  max-width: 260px;
}
.contentCol p {
  color: rgb(220, 220, 220);
}
.infoCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.row1,
.row2 {
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 35px;
}
.row1 {
  justify-content: space-between;
}
.row1 a {
  text-decoration: none;
}
.row2 {
  justify-content: start;
}
.iconText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  cursor: pointer;
}
.iconText p {
  color: rgb(220, 220, 220);
}
.iconText img {
  width: 32px;
  height: 32px;
}
.bottomRow {
  padding: 15px 0px 15px 0px;
  width: 100%;
  background-color: #ffffff;
}
.bottomRowInner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.socials {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 16px;
}
.socials a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #f0f0f0;
  transition: 0.2s ease;
}
.socials svg {
  width: 16px;
  height: 16px;
}
.socials a:hover {
  background-color: red;
}
.socials a:hover svg {
  fill: #fff;
}
@media (max-width: 992px) {
  .newsletterImgCont{
    display: flex;
    justify-content: center;
  }
  .imgCont {
    width: 80px;
    height: 80px;
  }
  .imgCont img {
    width: 40px;
    height: 40px;
  }
  .text h3,
  .text p {
    align-self: center;
    text-align: center;
  }
  .contentCol {
    padding-right: 0px;
    justify-content: center;
    align-items: center;
  }
  .contentCol p {
    text-align: center;
    align-self: center;
  }
  .row1,
  .row2 {
    gap: 20px;
  }
  .row1 {
    justify-content: center;
  }
  .row2{
    flex-direction: column-reverse;
    justify-content: center;
  }
  .iconText{
    width: 100%;
    justify-content: center;
  }
  .bottomRowInner {
    flex-direction: column;
    gap: 10px;
  }
  .bottomRowInner p {
    width: 100%;
    align-self: center;
    text-align: center;
  }
  .socials {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
