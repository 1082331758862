.desktopHeader {
  padding: 30px 0px 30px 0px;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(154, 154, 154, 0.587),
    rgba(188, 188, 188, 0.402)
  );
  position: fixed;
  top: 0;
  z-index: 7;
}
.sha_dow {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background: linear-gradient(
    to bottom,
    rgba(154, 154, 154, 0.587),
    rgba(188, 188, 188, 0.402)
  );
  box-shadow: 0px 1px 14px #f5f5f582;
}
.mobileHeader {
  display: none;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(154, 154, 154, 0.587),
    rgba(188, 188, 188, 0.402)
  );
  position: fixed;
  top: 0;
  z-index: 7;
}
.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.logo {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 80px;
}
.logo img {
  width: 220px;
  height: 45px;
}
.tabs {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 25px;
}
.link {
  text-decoration: none;
}
.link h5 {
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: 0.2s ease;
}
.link:hover h5 {
  color: rgb(235, 235, 235);
}
.drop_down {
  width: 100%;
  display: inline-block;
  margin-top: 105px;
  position: fixed;
  top: 0;
  z-index: 7;
}
.drop_downContent {
  border-top: 1px solid rgb(45, 45, 45);
  background-image: linear-gradient(to right, rgb(25, 25, 25), rgb(79, 79, 79));
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 1;
  animation: fadeInFromNone 0.5s ease-out;
}
@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
.listCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.listCol h3 {
  font-family: "Bebas Neue", sans-serif;
  text-align: start;
  align-self: flex-start;
  color: #c10f0f;
  font-size: 25px;
}
.listCol a {
  font-family: "Poppins", sans-serif;
  align-self: flex-start;
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}
.listColLink {
  color: #ffffff;
}
.listColLinkActive {
  color: #c10f0f;
}
.listCol a:hover {
  color: #e8e8e8;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: #c10f0f;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 60px 50px 60px 50px;
}
.head {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}
.head img {
  width: 24px;
  height: 24px;
  align-self: center;
}
.head h4 {
  color: white;
  text-align: start;
  align-self: flex-start;
}
.box p {
  color: white;
  text-align: start;
  align-self: flex-start;
}
.box a {
  align-self: flex-start;
}
.box button {
  align-self: flex-start;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 600;
  padding: 9px 16px 9px 16px;
  letter-spacing: 0.5px;
  margin: 0;
  border-radius: 4px;
  background-color: #ffffff;
  color: #c10f0f;
  border: 1px solid #c10f0f;
  transition: 0.2s ease-in;
}
.box button:hover {
  background-color: #c10f0f;
  color: white;
  border: 1px solid white;
}

@media (max-width: 1200px) {
  .logo {
    gap: 30px;
  }
  .desktopHeader {
    display: none;
  }
  .mobileHeader {
    display: block;
  }
}
