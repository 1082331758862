.main {
  padding: 100px 0px 100px 0px;
  width: 100%;
  background: #121315;
}
.imgCol,
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.imgCol img {
  align-self: flex-end;
  width: 100%;
}
.contentCol h1,
.contentCol h2,
.contentCol p {
  align-self: flex-start;
  text-align: start;
}
.contentCol h2 {
  color: #ec1d23;
}
.paraCont {
  direction: rtl;
  width: 100%;
  height: 270px;
  padding-left: 20px;
  overflow-y: scroll;
}
.paraCont p {
  color: #f1f1f1;
  direction: ltr;
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
  .imgCol img {
    align-self: center;
    max-width: 90%;
  }
  .contentCol {
    gap: 25px;
    justify-content: start;
    align-items: start;
  }
}
